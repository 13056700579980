<template>
  <a-layout id="components-layout-demo-custom-trigger">
    <a-layout-header style="height:64px; padding: 0;display: flex">
      <!--      <a-icon-->
      <!--          class="trigger"-->
      <!--          :type="collapsed ? 'menu-unfold' : 'menu-fold'"-->
      <!--          @click="() => (collapsed = !collapsed)"-->
      <!--      />-->
      <div class="logo" >
        <span @click="()=>{this.$router.push('/caseRoot')}" style="cursor: pointer">
          <img  style="width:170px;" src="../../../assets/case_logo.png" alt="">
        </span>
      </div>
      <a-menu
          theme="dark"
          mode="horizontal"
          :default-selected-keys="['caseRoot']"
          :style="{ lineHeight: '64px' }"
      >
<!--        <a-menu-item key="/" >-->
<!--          <router-link to="/">-->
<!--            社区运营管理后台-->
<!--          </router-link>-->
<!--        </a-menu-item>-->
        <!-- <a-menu-item key="/liveRoot" >
          <router-link to="/liveRoot">
            直播运营管理后台
          </router-link>
        </a-menu-item> -->
        <a-menu-item key="caseRoot">
          <router-link to="/caseRoot">
            病例收集管理后台
          </router-link>
        </a-menu-item>
<!--        <a-menu-item key="https://yun-manage.yunicu.com/appData/appHomepage" @click="go_appData">-->
<!--          数据分析管理后台-->
<!--        </a-menu-item>-->
      </a-menu>
<!--      <ManageHeader></ManageHeader>-->
    </a-layout-header>


  <a-layout>
    <a-layout-sider
        :trigger="null"
        collapsible
        breakpoint="xl"
        class="sider"
    >
      <a-menu theme="dark" mode="inline"
              v-model=select
              :default-selected-keys="select"

      >
        <a-menu-item key="0601">
          <router-link to="/caseRoot">
            <span>病例首页</span>
          </router-link>
        </a-menu-item>
        <a-menu-item key="0602">
          <router-link to="/caseRoot/caseProject">
            <span>病例项目管理</span>
          </router-link>
        </a-menu-item>
        <a-menu-item key="0607">
          <router-link to="/caseRoot/casePicturesCode">
            <span>病例图片打码管理</span>
          </router-link>
        </a-menu-item>
        <a-menu-item key="0603">
          <router-link to="/caseRoot/caseAudit">
            <span>病例审批管理</span>
          </router-link>
        </a-menu-item>
        <a-menu-item key="0604">
          <router-link to="/caseRoot/casePrint">
            <span>病例打印管理</span>
          </router-link>
        </a-menu-item>
        <a-menu-item key="0605">
          <router-link to="/caseRoot/caseAccount">
            <span>病例结算管理</span>
          </router-link>
        </a-menu-item>
        <a-menu-item key="0606">
          <router-link to="/caseRoot/caseProgress">
            <span>病例项目进展查询</span>
          </router-link>
        </a-menu-item>
        <a-menu-item key="0608">
          <router-link to="/caseRoot/targetHospitalAudit">
            <span>目标医院认证管理</span>
          </router-link>
        </a-menu-item>
        <a-menu-item key="0609">
          <router-link to="/caseRoot/caseSign">
            <span>病历签字管理</span>
          </router-link>
        </a-menu-item>
      </a-menu>
    </a-layout-sider>
    <a-layout-content
        :style="{ margin: '15px 16px', padding: '15px', background: '#fff', minHeight: '280px',
            overflowY:'scroll'}"
    >
      <router-view style="overflow-y: hidden"></router-view>


    </a-layout-content>

  </a-layout>
  </a-layout>
</template>

<script>
import ManageSider from "@/components/ManageSider";
import ManageHeader from "@/components/ManageHeader";
import ManageFooter from "@/components/ManageFooter";
export default {
  name: "CaseRoot",
  components: {ManageFooter, ManageHeader, ManageSider},
  data(){
    return{
      select:['0601'],
    }
  },
  watch:{
    $route(to,from){
      if (to.path==='/caseRoot'){
        this.select=['0601']
      }
    }
  },
  mounted() {
    const user_info = window.localStorage.getItem("user_info");
    this.userInfo =  JSON.parse(user_info)
  },
  methods:{
    go_appData(){
      const url = "https://yun-manage.yunicu.com/appData/appHomepage?byId="+this.userInfo.id
      location.href=url
    }
  }
}
</script>

<style scoped>
.ant-layout{
  height: 100%;
}
#components-layout-demo-custom-trigger .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
  height: 40px;
  display: flex;
  align-items: center;
  margin: 15px;
}
.sider{
  overflow-y: scroll;
}
</style>
